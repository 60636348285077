var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"level-item"},[_c('sp-link',{staticClass:"mr-3",attrs:{"tag":"b-button","to":{name:'categories.list'},"icon-left":"arrow-left"}}),( ! _vm.category)?_c('b-skeleton',{attrs:{"size":"is-medium","animated":"","width":"300"}}):[_c('h1',{staticClass:"title"},[_vm._v(" Edit "+_vm._s(_vm.category.name)+" ")])]],2)])]),(_vm.category)?_c('b-tabs',{staticClass:"block",attrs:{"destroy-on-hide":"","expanded":""},on:{"input":function($event){_vm.activeTab = $event}}},[_c('b-tab-item',{attrs:{"label":"General","value":"general","icon":"info-circle"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveCategory)}}},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Informations ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"name","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Name","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-field',[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.category.translations.name[_vm.currentLanguage]),callback:function ($$v) {_vm.$set(_vm.category.translations.name, _vm.currentLanguage, $$v)},expression:"category.translations.name[currentLanguage]"}}),_c('b-select',{model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}},_vm._l((_vm.$store.getters.languages),function(lang,l){return _c('option',{key:l,domProps:{"value":lang.code}},[_vm._v(" "+_vm._s(lang.name)+" ")])}),0)],1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"description","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Description","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"type":"textarea","disabled":_vm.isLoading},model:{value:(_vm.category.description),callback:function ($$v) {_vm.$set(_vm.category, "description", $$v)},expression:"category.description"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"active","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Status","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-switch',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.category.active),callback:function ($$v) {_vm.$set(_vm.category, "active", $$v)},expression:"category.active"}},[_vm._v(" Active ")])],1)]}}],null,true)})],1),_c('b-field',{attrs:{"horizontal":""}},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"save","disabled":! valid || _vm.isLoading},on:{"click":function($event){return _vm.saveCategory()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}],null,false,1124705699)})],1),_c('b-tab-item',{attrs:{"icon":"list","label":"Fields","value":"fields"}},[_c('fields',{attrs:{"active":_vm.activeTab === 'comments',"category":_vm.category,"error":_vm.errors,"loading":_vm.isLoading},on:{"error":function($event){return _vm.$emit('error',$event)},"success":function($event){return _vm.$emit('success',$event)}}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }