


























































































































































import Base from '@/mixins/Base.vue';

import { IObjectCategory, IObjectCategoryResponse } from '@/interfaces/objectcategory';

const component = Base.extend({
    data() {
        return {
            currentLanguage: 'en',
            activeTab: 0,
            category: undefined as any | IObjectCategory,

        };
    },
    mounted() {
        this.getCategory();
    },
    methods: {
        getCategory(): void {
            this.get<IObjectCategoryResponse>(`object-categories/${this.$route.params.id}`).then(({ data }) => {
                this.category = data.data;
            });
        },
        saveCategory(): void {
            this.put<IObjectCategoryResponse>(`object-categories/${this.$route.params.id}`, this.category)
                .then(({ data }) => {
                    this.category = data.data;
                    this.$emit('success', { message: `category ${data.data.name} updated` });
                });
        },

    },
});

export default component;
